import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminSideBar from "../../components/Admin/sidebar/individuals/AdminSideBar";
import Category from "../../components/controlled-by-admin/AddPosts/AddCategory";
import AddDirections from "../../components/controlled-by-admin/AddPosts/AddDirection";
import AddIngredients from "../../components/controlled-by-admin/AddPosts/AddIngridients";
import AddPosts from "../../components/controlled-by-admin/AddPosts/AddPosts";
import CustomerList from "../../components/controlled-by-admin/Customer List/CustomerList";
import AdminDashboard from "../../components/controlled-by-admin/Dashboard/Dashboard";
import Posts from "../../components/controlled-by-admin/posts/Posts";
import Header from "./AdminHeader";

const Admin = () => {
  return (
    <div className="flex text-xl">
      <AdminSideBar />
      <div className="flex flex-col flex-1">
        <Header />
        <Routes>
          <Route path="customers/allcustomers" element={<CustomerList />} />
          <Route path="posts/allposts/:title/:date" element={<Posts />} />
          <Route path="posts/ingredient/:title" element={<AddIngredients />} />
          <Route path="posts/direction/:title" element={<AddDirections />} />
          <Route path="posts/category/:title" element={<Category />} />
          <Route
            path="posts/allposts/:title/:date/add_post"
            element={<AddPosts />}
          />
          <Route path="dashboard" element={<AdminDashboard />} />
        </Routes>
      </div>
    </div>
  );
};
export default Admin;
