import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PhoneIcon from "@mui/icons-material/Phone";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { API } from "../../utils/API";
import { connect } from "react-redux";
import { toggleRole } from "../../redux/user/userAction";

const Login = ({ active, setActive, setRole }) => {
  const [response, setResponse] = useState("");
  const [color, setColor] = useState("");
  const [user, setUser] = useState({
    number: "",
    password: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user, //spread operator
      [name]: value,
    });
  };
  const login = async (e) => {
    e.preventDefault();
    try {
      const result = await API.post("api/auth/signin", user)
        .then((res) => {
          // console.log('this is from response', res.message);
          return res;
        })
        .catch((err) => err);
      console.log("this is from result", result);

      if (result.status === 200) {
        console.log("logged in seccessfully");
        setResponse("logged in successfully");
        setColor("red");
        const roles = result.data.role;
        console.log("this is roles as usual", roles);
        setRole(roles);
        // navigate(from, { replace: true });
        console.log(result.data.role);
        if (roles === "user") {
          navigate(`/home`);
        } else {
          navigate(`/${result.data.role}/dashboard`);
        }
      } else {
        setResponse("Incorrect phone number or password");
      }
      // console.log('this is from result', result.status);
    } catch (error) {
      console.log("this is from error", error);
    }
  };

  return (
    <div
      className={`${
        active ? "hidden" : ""
      } relative flex flex-col justify-center items-center h-screen`}
    >
      <div className="flex flex-col">
        <div
          className="flex self-end w-10 text-red-900 scale-100 cursor-pointer"
          onClick={() => setActive(!active)}
        >
          <CloseIcon fontSize="large" />
        </div>
        <div className="bg-white flex shadow-xl border rounded-2xl">
          <form action="" onSubmit={login}>
            <div className="bg-white relative w-96 h-100 md:w-120 md:h-120 flex flex-col p-5 justify-center items-center rounded-2xl">
              <h1 className="text-2xl absolute font-bold top-10 text-blue-500">
                <span>WELCOME TO GEBETAYE</span>
              </h1>
              <h3 className="font-semibold text-base my-4 text-slate-500 italic">
                Signin with phone number and password
              </h3>
              <h3
                className={`text-base w-full flex font-medium border p-1 rounded ${
                  !color === "red" ? "border-red-500" : "border-sky-500"
                } text-red-500 italic`}
              >
                {response}
              </h3>
              <div className="flex flex-col w-full">
                <label className="w-full">Number</label>
                <div className="w-full flex justify-center items-center">
                  <input
                    className="italic h-12 w-full border border-gray-400 rounded my-3 px-2 focus:outline-none focus:border-blue-500"
                    type="number"
                    name="number"
                    placeholder="Number"
                    value={user.number}
                    onChange={handleChange}
                    required
                  />
                  <PhoneIcon className="-ml-7" />
                </div>
              </div>
              <div className="flex flex-col w-full">
                <label htmlFor="">Password</label>
                <div className="w-full flex justify-center items-center">
                  <input
                    className="italic h-12 w-full  border border-gray-400 rounded my-3 px-2 focus:outline-none focus:border-blue-500"
                    type="password"
                    name="password"
                    value={user.password}
                    onChange={handleChange}
                    placeholder="Password"
                    required
                  />
                  <LockOutlinedIcon fontSize="medium" className="-ml-7" />
                </div>
              </div>
              <div className="flex items-center justify-start w-full">
                <input
                  type="checkbox"
                  className="rounded text-pink-500 mx-2 my-4"
                />
                <span>Remember Me</span>
              </div>
              <input
                className="px-6 py-2 border bg-blue-500 cursor-pointer text-xl text-white font-medium rounded hover:bg-blue-700"
                type="submit"
                value="Log In"
              />
              <Link
                to="/forgotpassword"
                className="flex mt-3 justify-center text-blue-400 hover:text-blue-600"
              >
                Forgot Password?
              </Link>
              <div className="ui divider w-full"></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  setRole: (item) => dispatch(toggleRole(item)),
});

export default connect(null, mapDispatchToProps)(Login);
