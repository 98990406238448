import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import adminReducer from "./admin/adminReducer";
import userReducer from "./user/userReducer";
import jobReducer from "./jobs/jobReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "jobs"],
};
const rootReducer = combineReducers({
  admin: adminReducer,
  jobs: jobReducer,
  user: userReducer,
});

export default persistReducer(persistConfig, rootReducer);
