import React, { useState } from "react";
import Login from "../../components/Login/Login";

const LoginPage = () => {
  const [active, setActive] = useState(false);
  return (
    <div className="max-h-screen overflow-hidden">
      <Login active={active} setActive={setActive} />
    </div>
  );
};

export default LoginPage;
