import React, { useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
// import { selectHospitalID } from "../../redux/user/userSelector";
// import { createStructuredSelector } from "reselect";
// import { connect } from "react-redux";
// import { API } from "../../../utils/API";

const PostLists = ({
  data,
  handleDeleteClick,
  title,
  handleAddClick,
  email,
  date,
}) => {
  const [filtered, setFiltered] = useState(10);
  // const MySwal = withReactContent(Swal);
  // const [id, setId] = useState();
  const [activeHidden, setActiveHidden] = useState(false);
  const [search, setSearch] = useState({
    patient: "",
  });
  const [user, setUser] = useState([]);
  const [hideDetails, setHideDetails] = useState(true);
  // const Alert = (message) => {
  //   MySwal.fire({
  //     position: "center",
  //     icon: "success",
  //     title: message,
  //     showConfirmButton: false,
  //     timer: 1500,
  //   });
  // };
  const handleFilter = (e) => {
    if (e.target.value === "ALL") {
      setFiltered(1000);
    } else {
      setFiltered(e.target.value);
    }
  };
  const handleEditClick = (id, item) => {
    // setId(id);
    setUser(item);
    setActiveHidden(true);
  };

  // edit handlers
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setUser({
  //     ...user, //spread operator
  //     [name]: value,
  //   });
  // };
  const handleDetailsClick = (id, item) => {
    // setId(id);
    setUser(item);
    setHideDetails(false);
  };
  const handleSearch = (e) => {
    const { name, value } = e.target;
    setSearch({
      [name]: value,
    });
  };
  // const updateUser = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const result = await API.patch(
  //       `api/system/patients/updatepatient/${id}`,
  //       user
  //     ).then((res) => {
  //       console.log(res);
  //       return res;
  //     });
  //     if (result.status === 200) {
  //       const message = "Updated Successfully";
  //       Alert(message);
  //     } else {
  //       const message = "failed";
  //       Alert(message);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   setActiveHidden(false);
  // };

  return (
    <div>
      <div
        className={`add-patient flex flex-col my-6 mx-12 bg-white border-gray-700 rounded-md shadow ${
          activeHidden || !hideDetails ? "hidden" : ""
        }`}
      >
        <h3 className="add-patient-header bg-blue-300 h-12 rounded-tr-md flex p-4 items-center rounded-tl-md">
          {title}
        </h3>
        <div className="ui divider"></div>
        <div className="flex justify-between px-4">
          <div className="field text-sm">
            <select
              className="ui dropdown"
              name="filter"
              value={filtered}
              onChange={handleFilter}
            >
              <option>ALL</option>
              <option>10</option>
              <option>20</option>
              <option>50</option>
              <option>100</option>
            </select>
          </div>
          <button
            className="text-sm bg-sky-400 p-2 text-white"
            onClick={handleAddClick}
          >
            Add New
          </button>
        </div>
        <div className="ui divider"></div>
        <div className="flex flex-col px-4 pb-4">
          <div className="ui right icon input h-8 w-40 self-end mb-2">
            <input
              type="text"
              className="text-sm"
              placeholder="Search customers..."
              name="patient"
              value={search.patient}
              onChange={handleSearch}
            />
            <i className="user icon small"></i>
            {/* <i className="search icon"></i> */}
          </div>
          <table className="border-collapse border border-gray-200">
            <thead>
              <tr className="items-center gap-0 border border-gray-300 bg-slate-200 text-lg">
                <th className="border border-gray-300 ">ID</th>
                <th className="border border-gray-300 ">Title</th>
                <th className="border border-gray-300 ">Body</th>
                <th className="border border-gray-300 ">Photo</th>
                <th className="border border-gray-300 ">Created At</th>
                <th className="border border-gray-300 ">Options</th>
              </tr>
            </thead>
            <tbody>
              {data !== null && data instanceof Array
                ? data
                    // eslint-disable-next-line array-callback-return
                    .filter((val) => {
                      if (date) {
                        if (date === "all") {
                          return val;
                        }
                        // eslint-disable-next-line eqeqeq, no-unused-expressions
                        return val.created_at == date;
                      } else {
                        return val;
                      }
                    })
                    // eslint-disable-next-line array-callback-return
                    .filter((val) => {
                      if (search.patient === "") {
                        return val;
                      } else if (
                        val.jobTitle
                          .toLowerCase()
                          .includes(search.patient.toLowerCase())
                      ) {
                        return val;
                      }
                    })
                    .filter((item, index) => index < filtered)
                    .map((item) => {
                      return (
                        <tr key={item.id} className="text-base">
                          <td className="border-y py-1">
                            <span className="p-1 px-4">{item.id}</span>
                          </td>
                          <td className="border py-1 self-center">
                            <span className="p-1 px-1">{item.title}</span>
                          </td>
                          <td className=" border-y border-r py-1 self-center">
                            <span className="p-1 px-1">{item.body}</span>
                          </td>
                          <td className=" border-y border-r py-1 self-center">
                            <span className="p-1 px-1">{item.photo}</span>
                          </td>
                          <td className=" border-y border-r py-1 self-center">
                            <span className="p-1 px-1">{item.created_at}</span>
                          </td>
                          <td className="p-1 flex py-1 self-end">
                            <button
                              className="info-button border rounded h-8 text-white px-2 mx-1 flex items-center"
                              onClick={() => handleDetailsClick(item.id, item)}
                            >
                              <InfoIcon fontSize="small" />
                              <span>Details</span>
                            </button>
                            <button
                              className="edit-button border rounded h-8 text-gray-500 px-2 mx-1 flex items-center"
                              onClick={() => handleEditClick(item.id, item)}
                            >
                              <EditIcon fontSize="small" />
                              <span>Edit</span>
                            </button>
                            <button
                              className="delete-button border rounded h-8 text-white px-2 mx-1 left-1 flex items-center"
                              onClick={() => handleDeleteClick(item.id)}
                            >
                              <DeleteIcon fontSize="small" />
                              <span>Delete</span>
                            </button>
                          </td>
                        </tr>
                      );
                    })
                : null}
            </tbody>
          </table>
          <div className="ui divider"></div>
        </div>
      </div>

      {/* ///////////////// */}
      <div
        className={`flex flex-col w-5/6 bg-white m-4 rounded border shadow sticky top-1/4 ${
          hideDetails ? "hidden" : ""
        }`}
      >
        <h3 className="flex justify-center pt-4 font-bold text-2xl text-gray-700 h-12 items-center">
          More Details
        </h3>
        <div className="ui divider"></div>
        <div className="flex justify-around p-4">
          <div>
            <div className="w-40 h-40 rounder border">
              <img className=" flex" src="" alt="user_Image" />
            </div>
            <span>{user.role}</span>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col">
              <span className="font-bold text-gray-600 py-2 ">Name:</span>
              <span>{user.name}</span>
            </div>
            <div className="flex flex-col">
              <span className="font-bold text-gray-600 py-2">Age:</span>
              <span>{user.age}</span>
            </div>
            <div className="flex flex-col">
              <span className="font-bold text-gray-600 py-2">BloodGroup:</span>
              <span>{user.bloodGroup}</span>
            </div>
            <div className="flex flex-col">
              <span className="font-bold text-gray-600 py-2">Birth Date:</span>
              <span>{user.birthDate}</span>
            </div>
            <div className="flex flex-col">
              <span className="font-bold text-gray-600 py-2">Phone:</span>
              <span>{user.phone}</span>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col">
              <span className="font-bold text-gray-600 py-2">Email:</span>
              <span>{user.email}</span>
            </div>
            <div className="flex flex-col">
              <span className="font-bold text-gray-600 py-2">Gender:</span>
              <span>{user.gender}</span>
            </div>
            <div className="flex flex-col">
              <span className="font-bold text-gray-600 py-2">
                Marital Status:
              </span>
              <span>{user.maritalStatus}</span>
            </div>
            <div className="flex flex-col">
              <span className="font-bold text-gray-600 py-2">Address:</span>
              <span>{user.address}</span>
            </div>
            <div className="flex flex-col">
              <span className="font-bold text-gray-600 py-2">other:</span>
              <span>...........</span>
            </div>
          </div>
        </div>
        <div className="ui divider"></div>
        <button
          className="p-2 m-2 bg-blue-500 hover:bg-blue-600 justify-center self-end items-center rounded text-white font-bold"
          onClick={() => setHideDetails(true)}
        >
          Ok
        </button>
      </div>
    </div>
  );
};
// const mapStateToProps = createStructuredSelector({
//   email: selectHospitalID,
// });
export default PostLists;
