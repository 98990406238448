import React from "react";
import { Routes, Route } from "react-router-dom";

import "./App.css";
import LoginPage from "./pages/Login/LoginPage";
import Admin from "./pages/Admin/Admin";
import Unauthorized from "./utils/Unauthorized";
import RequireAuth from "./components/RequireAuth/RequireAuth";
function App() {
  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route element={<RequireAuth allowedRoles={"admin"} />}>
          <Route path="/admin/*" element={<Admin />} />
        </Route>
        <Route path="/unauthorized" element={<Unauthorized />} />
      </Routes>
    </div>
  );
}

export default App;
