import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ReportIcon from "@mui/icons-material/Report";
import RowingIcon from "@mui/icons-material/Rowing";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PaidIcon from "@mui/icons-material/Paid";
import AlarmAddSharpIcon from "@mui/icons-material/AlarmAddSharp";
import HolidayVillageSharpIcon from "@mui/icons-material/HolidayVillageSharp";
import AddCommentIcon from "@mui/icons-material/AddComment";
import TodayIcon from "@mui/icons-material/Today";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import LockIcon from "@mui/icons-material/Lock";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

export const sideBarData = [
  {
    id: 1,
    title: "Dashboard",
    path: "dashboard",
    icon: <DashboardIcon />,
    hidden: true,
  },
  {
    id: 41,
    title: "Roles",
    path: "roles",
    icon: <LockIcon />,
    hidden: true,
  },
  {
    id: 2,
    title: "Customers",
    path: "customers/allcustomers",
    icon: <PersonIcon />,
    hidden: true,
    subContent: [
      {
        title: "Customer List",
        path: "customers/allcustomers",
        icon: <RecentActorsIcon />,
      },
      {
        title: "Add Customer",
        path: "customers/allcustomers/add_customers",
        icon: <PersonAddIcon />,
      },
      {
        title: "Payments",
        path: "customers/payments",
        icon: <PaidIcon />,
      },
      // { hidden: true },
    ],
  },

  {
    id: 3,
    title: "Agents",
    path: "agent/agents",
    icon: <CalendarViewMonthIcon />,
    hidden: true,
    subContent: [
      {
        title: "All Agents",
        path: "schedule/schedules",
        icon: <AlarmAddSharpIcon />,
      },
      {
        title: "Add Agents",
        path: "agent/agents/add_agent",
        icon: <HolidayVillageSharpIcon />,
      },
      // { hidden: true },
    ],
  },
  {
    id: 4,
    title: "English Posts",
    path: "posts/allposts/engposts/all",
    icon: <BookOnlineIcon />,
    hidden: true,
    subContent: [
      {
        title: "English Posts",
        path: "posts/allposts/engposts/all",
        icon: <AddCommentIcon />,
      },
      {
        title: "Today posts",
        path: `posts/allposts/engposts/${new Date().toISOString()}`,
        icon: <TodayIcon />,
      },
      {
        title: "Ingredients",
        path: "posts/ingredient/eng_ingredient",
        icon: <BookOnlineIcon />,
      },
      {
        title: "Category",
        path: "posts/category/eng_category",
        icon: <TodayIcon />,
      },
      {
        title: "Direction",
        path: "posts/direction/eng_direction",
        icon: <ReplyAllIcon />,
      },
    ],
  },
  {
    id: 94,
    title: "Amharic Posts",
    path: "posts/allposts/amharicposts/all",
    icon: <BookOnlineIcon />,
    hidden: true,
    subContent: [
      {
        title: "Amharic Posts",
        path: "posts/allposts/amharicposts/all",
        icon: <AddCommentIcon />,
      },
      {
        title: "Today posts",
        path: `posts/allposts/amharicpost/${new Date().toISOString()}`,
        icon: <TodayIcon />,
      },
      {
        title: "Ingredients",
        path: "posts/ingredient/amharic_",
        icon: <BookOnlineIcon />,
      },
      {
        title: "Category",
        path: "posts/category/amharicpost",
        icon: <TodayIcon />,
      },
      {
        title: "Direction",
        path: "posts/direction/amharicpost",
        icon: <ReplyAllIcon />,
      },
    ],
  },
  {
    id: 5,
    title: "Categories",
    path: "categories",
    icon: <CalendarTodayIcon />,
    hidden: true,
  },
  {
    id: 52,
    title: "calender",
    path: "calender",
    icon: <CalendarTodayIcon />,
    hidden: true,
  },
  {
    id: 6,
    title: "Reports",
    path: "reports",
    icon: <ReportIcon />,
    hidden: true,
  },
  {
    id: 7,
    title: "User Activity Report",
    path: "user_activity",
    icon: <RowingIcon />,
    hidden: true,
  },
  {
    id: 8,
    title: "Settings",
    path: "settings",
    icon: <ManageAccountsIcon />,
    hidden: true,
    subContent: [
      {
        title: "Profile",
        path: "settings/profile",
        icon: <ManageAccountsIcon />,
      },
      {
        title: "Logout",
        path: "/",
        icon: <ExitToAppIcon />,
      },
    ],
  },
];
