import { Avatar } from "@mui/material";
import React from "react";
import userIcon from "./../../assets/user.png";

// import './header.scss';

const Header = () => {
  return (
    <div className="sticky shadow flex h-20 w-full px-12 items-center justify-between bg-white text-white">
      <div className="flex text-gray-600 self-center pl-10 font-bold text-2xl flex justify-center items-center">
        JOBS AND SCHOLARSHIP ADMIN PANAL
      </div>
      <div className="flex cursor-pointer border rounded px-2 py-1">
        <div className="flex justify-center items-center mx-2">
          <Avatar
            alt="Remy Sharp"
            sx={{ width: 24, height: 24 }}
            src={userIcon}
          />
        </div>
        <div className="field text-sm text-black">
          <select className="ui dropdown" name="filter" value="admin">
            <option>admin</option>
            <option>Home</option>
            <option>Profile</option>
            <option>Logout</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Header;
