import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  toggleActiveMenu,
  toggleHiddenItem,
} from "../../../../redux/admin/adminAction";
import {
  selectActive,
  selectHiddenToggle,
  selectSideBarData,
} from "../../../../redux/admin/adminSelector";
import SideBar from "../SideBar";

import "./styles.scss";

const AdminSideBar = ({
  data,
  active,
  toggleActive,
  content,
  toggleHidden,
}) => {
  return (
    <div
      className={`side-menu w-86 sticky left-0 text-white h-screen bg-gray-50 shadow  p-2 z-[100] border-r border-gray-200 ${
        active ? "active" : ""
      }`}
    >
      <SideBar
        data={data}
        active={active}
        toggleActive={toggleActive}
        content={content}
        toggleHidden={toggleHidden}
      />
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  data: selectSideBarData,
  active: selectActive,
  content: selectHiddenToggle,
});

const mapDispatchToProps = (dispatch) => ({
  toggleActive: (active) => dispatch(toggleActiveMenu(active)),
  toggleHidden: (hidden) => dispatch(toggleHiddenItem(hidden)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminSideBar);
