import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import SideBarItems from "./SideBarItems";
import logo from "../../../assets/admin.png";
import "./sidebar.scss";

const SideBar = ({ data, active, toggleActive, content, toggleHidden }) => {
  const current = new Date().toISOString().split("T")[0];
  return (
    <div className="forall w-full h-full">
      <div className="relative flex justify-center bg-gray-300">
        <div className="logo flex justify-around w-full items-center">
          <div className="flex flex-col items-start">
            <div className="flex justify-between py-3">
              <img
                className="image-reducer w-28 h-28 border rounded-full shadow"
                src={logo}
                alt="logoImage"
              />
              {!active ? (
                <div className="flex flex-col px-2 text-gray-700">
                  <span className="flex self-center p-1 text-white text-base bg-blue-600 font-serif uppercase rounded border">
                    Admin
                  </span>
                  <span className="text-lg">Name: Yared Mesele</span>
                  <span className="text-lg">E-mail: yado@admin.com</span>
                  <span className="text-lg">{current}</span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="try -right-12 py-3 absolute cursor-pointer text-black">
          <MenuIcon fontSize="large" onClick={() => toggleActive()} />
        </div>
      </div>
      <div className="ui divider border-4"></div>
      <div className="forscroll overflow-y-hidden h-full w-full text-white">
        <div>
          <SideBarItems
            items={data}
            active={active}
            content={content}
            toggleHidden={toggleHidden}
          />
        </div>
      </div>
    </div>
  );
};

// const mapStateToProps = createStructuredSelector({
//   role: selectRole,
// });

export default SideBar;
