import React, { useState } from "react";
import Swal from "sweetalert2";
// import { useNavigate } from 'react-router-dom';
import { API } from "./../../../utils/API";
import withReactContent from "sweetalert2-react-content";
import { useParams } from "react-router-dom";

const MySwal = withReactContent(Swal);
const Alert = (status, message) => {
  MySwal.fire({
    position: "center",
    icon: status,
    title: message,
    showConfirmButton: false,
    timer: 1500,
  });
};
const Category = () => {
  const params = useParams();
  const title = params.title;
  const current = new Date().toISOString().split("T")[0];
  const [job, setJob] = useState({
    id: 0,
    title: "",
    body: "",
    photo: "",
    amcategory_id: "",
    user_id: 1,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setJob({
      ...job, //spread operator
      [name]: value,
    });
  };
  const registerjob = async (e) => {
    e.preventDefault();
    try {
      const result = await API.post(`api/category?title=${title}`, job).then(
        (res) => {
          console.log(res);
          return res;
        }
      );
      if (result.status === 201) {
        Alert(result.data.status, result.data.message);
      } else {
        Alert(result.status, result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="add-job flex flex-col my-10 m-16 bg-white border-gray-700 rounded-md shadow">
      <h3 className="add-job-header bg-blue-300 h-12 rounded-tr-md flex p-4 items-center rounded-tl-md text-black">
        Add New Category
      </h3>
      <form
        action=""
        className="ui form flex flex-col p-4"
        onSubmit={registerjob}
      >
        <div className="field flex items-center justify-end border-b-2">
          <label className="p-1" htmlFor="">
            Today's Date:
          </label>
          <label className="p-1">{current}</label>
        </div>
        <div className="flex-flex-col border-2 rounded my-2">
          <h3 className="ui block header h-10 flex text-4xl justify-center items-center">
            Category Information
          </h3>
          <div className="two fields p-2">
            <div className="field">
              <label htmlFor="" className="label">
                Title:
              </label>
              <input
                type="text"
                placeholder="Job-Title"
                name="jobTitle"
                required
                value={job.title}
                onChange={handleChange}
              />
            </div>
            <div className="field">
              <label htmlFor="" className="label">
                Photo:
              </label>
              <input
                type="text"
                placeholder="Photo"
                name="photo"
                value={job.photo}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          {/* <div className="one fields p-2"> */}
          <div className="field p-2">
            <label htmlFor="" className="label">
              Body:
            </label>
            <textarea
              type="text"
              placeholder="Category"
              rows={4}
              name="category"
              required
              value={job.body}
              onChange={handleChange}
            />
          </div>

          <div className="two fields p-2">
            <div className="field">
              <label htmlFor="" className="label">
                Category ID:
              </label>
              <input
                type="number"
                placeholder="Category ID"
                name="amcategory_id"
                required
                value={job.amcategory_id}
                onChange={handleChange}
              />
            </div>
            <div className="field">
              <label htmlFor="" className="label">
                User ID:
              </label>
              <input
                type="number"
                placeholder="User ID"
                name="salary"
                required
                value={job.user_id}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="ui divider"></div>
        <button className="p-2 m-2 w-32 bg-blue-500 hover:bg-blue-600 justify-center items-center rounded text-white font-bold">
          SUBMIT
        </button>
      </form>
    </div>
  );
};
// const mapStateToProps = createStructuredSelector({
//   hospitalData: selectHospitalData,
// });
export default Category;
