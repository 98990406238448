import React, { useState } from "react";
import Swal from "sweetalert2";
// import { useNavigate } from 'react-router-dom';
import { API } from "./../../../utils/API";
import withReactContent from "sweetalert2-react-content";
import { useParams } from "react-router-dom";

const MySwal = withReactContent(Swal);
const Alert = (status, message) => {
  MySwal.fire({
    position: "center",
    icon: status,
    title: message,
    showConfirmButton: false,
    timer: 1500,
  });
};
const AddIngredients = () => {
  const params = useParams();
  const title = params.title;
  const current = new Date().toISOString().split("T")[0];
  const [job, setJob] = useState({
    en_id: null,
    ingeredient: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setJob({
      ...job, //spread operator
      [name]: value,
    });
  };
  const registerjob = async (e) => {
    e.preventDefault();
    try {
      const result = await API.post(`api/ingredient?title=${title}`, job).then(
        (res) => {
          console.log(res);
          return res;
        }
      );
      if (result.status === 201) {
        Alert(result.data.status, result.data.message);
      } else {
        Alert(result.status, result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="add-job flex flex-col my-10 m-16 bg-white border-gray-700 rounded-md shadow">
      <h3 className="add-job-header bg-blue-300 h-12 rounded-tr-md flex p-4 items-center rounded-tl-md text-black">
        Add New Ingredient
      </h3>
      <form
        action=""
        className="ui form flex flex-col p-4"
        onSubmit={registerjob}
      >
        <div className="field flex items-center justify-end border-b-2">
          <label className="p-1" htmlFor="">
            Today's Date:
          </label>
          <label className="p-1">{current}</label>
        </div>
        <div className="flex-flex-col border-2 rounded my-2">
          <h3 className="ui block header h-10 flex text-4xl justify-center items-center">
            Ingredient Information
          </h3>
          <div className="two fields p-2">
            <div className="field">
              <label htmlFor="" className="label">
                ID:
              </label>
              <input
                type="number"
                placeholder="ID"
                name="en_id"
                required
                value={job.en_id}
                onChange={handleChange}
              />
            </div>
            <div className="field">
              <label htmlFor="" className="label">
                Ingredient:
              </label>
              <input
                type="text"
                placeholder="Ingeredient"
                name="ingeredient"
                value={job.ingeredient}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          {/* <div className="one fields p-2"> */}
        </div>
        <div className="ui divider"></div>
        <button className="p-2 m-2 w-32 bg-blue-500 hover:bg-blue-600 justify-center items-center rounded text-white font-bold">
          SUBMIT
        </button>
      </form>
    </div>
  );
};
// const mapStateToProps = createStructuredSelector({
//   hospitalData: selectHospitalData,
// });
export default AddIngredients;
