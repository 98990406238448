import React from "react";
// import Remainder from "./Remainder";
import customers from "../../../assets/customers.png";
// import doctors from "../../assets/doctor-vector1.jpg";
// import rooms from "../../assets/rooms.png";
import CountUp from "react-countup";
import LineChart from "../../Charts/LineChart";
import { chartData } from "./../../Charts/data";

const AdminDashboard = ({ email }) => {
  //   useEffect(() => {
  //     const fetchdata = async () => {
  //       const result = await API1.get("api/system/patients");
  //       setData1(result.data.patients);
  //     };
  //     fetchdata();
  //     const fetchStaff = async () => {
  //       const result = await API1.get("api/system/staffs");
  //       console.log(result);
  //       setData2(result.data.data.staffs);
  //     };
  //     fetchStaff();
  //   }, []);
  //   useEffect(() => {
  //     const patientCount = data1.filter(
  //       (val) => val.hospitalEmail === email
  //     ).length;
  //     setPatientcount(patientCount);
  //     const doctorCount = data2.filter(
  //       (val) => val.hospitalEmail === email && val.role === "doctor"
  //     ).length;
  //     setDoctorcount(doctorCount);
  //   }, [data1, email, data2]);

  //   console.log(data2);

  const data = {
    labels: chartData.map((data) => data.month),
    datasets: [
      {
        label: "Payment gained",
        data: chartData.map((data) => data.hospitalGain),
        pointHoverRadius: 4,
        color: `rgba(134, 65, 244, 0.2)`,
        backgroundColor: "blue",
        borderWidth: 3,
        borderColor: "rgba(0,0,255,1)",
        lineTension: 0.5,
      },
      {
        label: "Payment lost",
        lineTension: 0.5,
        data: chartData.map((data) => data.hospitalLost),
        pointHoverRadius: 5,
        backgroundColor: "red",
        borderColor: "rgba(255,0,0,1)",
        borderWidth: 3,
      },
    ],
  };
  return (
    <div className="grid grid-cols-6 gap-4">
      <div className=" m-6 col-span-4">
        <div className="flex flex-wrap items-center justify-evenly my-6 p-6 bg-gray-100 border rounded shadow">
          <div className="flex border items-center rounded shadow w-52 h-36 border-blue-500 bg-blue-600 p-2">
            {/* <PeopleAltIcon fontSize="large" /> */}
            <img
              className="w-20 h-20 rounded-full"
              src={customers}
              alt="patients"
            />
            <div className="flex flex-col  items-center p-2">
              <CountUp
                className="text-white text-3xl font-semibold mb-3"
                start={0}
                end={14020}
                duration={4}
                delay={0}
              />
              <span className="text-lg text-white">Total Customers</span>
            </div>
          </div>
          <div className="flex border h-36 items-center rounded shadow w-52 border-sky-500 bg-sky-600 p-2 mx-2">
            <img
              className="w-20 h-20 rounded-full"
              src={customers}
              alt="patients"
            />
            <div className="flex flex-col items-center p-2">
              <CountUp
                className="text-white text-3xl font-semibold mb-3"
                start={0}
                end={3200}
                duration={4}
                delay={0}
              />
              <span className="text-lg text-white">Paid Customers</span>
            </div>
          </div>
          <div className="flex border h-36 items-center rounded shadow w-52 border-green-500 bg-green-600 p-2 mx-2">
            <img
              className="w-20 h-20 rounded-full"
              src={customers}
              alt="patients"
            />
            <div className="flex flex-col items-center p-2">
              <CountUp
                className="text-white text-3xl font-semibold mb-3"
                start={0}
                end={1800}
                duration={4}
                delay={0}
              />
              <span className="text-lg text-white">Total Posts</span>
            </div>
          </div>

          <div className="flex border h-36 items-center rounded shadow w-52 border-red-500 bg-red-600 p-2 mx-2">
            <img
              className="w-20 h-20 rounded-full"
              src={customers}
              alt="patients"
            />
            <div className="flex flex-col items-center p-2">
              <CountUp
                className="text-white text-3xl font-semibold mb-3"
                start={0}
                end={320}
                duration={4}
                delay={0}
              />
              <span className="text-lg text-white">Unsubscribers</span>
            </div>
          </div>
        </div>
        <div className="bg-white my-4 rounded border shadow">
          <span className="bg-gray-100 p-2 mb-2 rounded border flex">
            Payment Statistics
          </span>
          <div className="p-3">
            <LineChart data={data} />
          </div>
        </div>
        <div className="my-2 bg-white h-80 border rounded shadow">
          <span className="bg-gray-100 p-2 mb-2 rounded border flex">
            This Week Payments
          </span>
          <div className="p-3">
            <div className="text-gray-400">there is no payment</div>
          </div>
        </div>
      </div>
      <div className="col-span-2 border rounded shadow m-6 h-screen bg-white">
        {/* <Remainder /> */}
        <span className="bg-gray-100 p-2 mb-2 rounded border flex">
          Remenders
        </span>
      </div>
    </div>
  );
};
// const mapStateToProps = createStructuredSelector({
//   email: selectHospitalID,
// });
export default AdminDashboard;
