import { createSelector } from "reselect";

const selectData = (state) => state.admin;

export const selectSideBarData = createSelector(
  [selectData],
  (admin) => admin.data
);
export const selectActive = createSelector(
  [selectData],
  (admin) => admin.active
);
export const selectHiddenToggle = createSelector(
  [selectData],
  (admin) => admin.content
);
export const selectHidden = createSelector(
  [selectData],
  (admin) => admin.hidden
);
