import { userActionTypes } from "./userTypes";

const INITIAL_STATE = {
  jobs: [],
  role: {},
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userActionTypes.SET_ALL_JOBS:
      return {
        ...state,
        jobs: action.payload,
      };
    case userActionTypes.TOGGLE_ROLE:
      return {
        ...state,
        role: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
